import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetch from "../helpers/fetch";

export default function Repo(props) {
  const { org, repo } = useParams();
  const [repoData, setRepoData] = useState(null);

  useEffect(() => {
    const doFetch = async () => {
      const { data } = await fetch(`/api/repo/${org}/${repo}`, {});
      setRepoData(data);
    };
    doFetch();
  }, [org, repo]);

  return (
    <div>
      {repoData?.Commit.length ? (
        <div className="mx-4 my-4 rounded-md py-4 px-12 flex justify-between bg-slate-600 shadow">
          <h1>Last upload</h1>
          <p>{new Date(repoData.Commit[0].updatedAt).toLocaleDateString()}</p>
        </div>
      ) : (
        /* <div className="mx-4 my-4 rounded-md py-4 px-12 flex justify-between bg-slate-600 shadow">
        <div className="">
          <h1 className="text-sm mb-2 uppercase text-slate-300">
            Current coverage
          </h1>
          <p className="text-4xl">73%</p>
        </div>

        <div className="">
          <h1 className="text-sm mb-2 uppercase text-slate-300">
            Avg diff coverage
          </h1>
          <p className="text-4xl">54%</p>
        </div>

        <div className="">
          <h1 className="text-sm mb-2 uppercase text-slate-300">Top tester</h1>
          <p className="text-4xl">@mega-man</p>
        </div>

        <div className="">
          <h1 className="text-sm mb-2 uppercase text-slate-300">
            Trend last 30d
          </h1>
          <p className="text-4xl">+2%</p>
        </div>
      </div>
      <div className="py-4 px-8">
        <div className="flex flex-row space-x-2">
          <div className="flex flex-row mt-8 flex-1">
            <div className="w-full bg-slate-600">
              <div className="bg-slate-400 py-2 px-4">
                Recommended files to test
              </div>
              <div className="py-2 px-4 space-y-1">
                <div>/src/components/Jibber.js</div>
                <div>/src/components/Dirk.js</div>
                <div>/src/components/Sirk.js</div>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-8 flex-1">
            <div className="w-full bg-slate-600">
              <div className="bg-slate-400 py-2 px-4">Latest PRs</div>
              <div className="py-2 px-4 space-y-1">
                <div className="flex">
                  <div className="flex-1">#101 Build button</div>
                  <div>72%</div>
                </div>
                <div className="flex">
                  <div className="flex-1">#103 Fix bug</div>
                  <div>49%</div>
                </div>
                <div className="flex">
                  <div className="flex-1">#109 Revert PR #100</div>
                  <div>0%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */
        <div className="bg-slate-600 m-6 rounded p-4">
          <h1 className="font-bold text-center mb-6">No coverage data (yet)</h1>
          <p>
            To start uploading add the following snippet to your build-script
            after tests have run
          </p>
          <pre className="font-mono p-2 bg-slate-500 w-fit mt-2 mb-4">
            npx captain-cov
          </pre>
          <p>
            And add your repo token to the environment-variable{" "}
            <span className="font-mono">CAPTAIN_COV_TOKEN</span>
          </p>
          <pre className="font-mono p-2 bg-slate-500 w-fit mt-2 mb-4">
            {repoData?.uuid}
          </pre>
        </div>
      )}
    </div>
  );
}
