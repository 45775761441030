export default function Login() {
  return (
    <div className="min-h-screen min-w-screen flex justify-center">
      <div className="bg-white bg-opacity-10 backdrop-blur-md h-auto self-center py-4 px-8 text-center">
        <h1 className="text-4xl font-extralight text-center mb-6">
          Welcome to Captain Cov
        </h1>
        <a
          href="/api/github/oauth"
          className="text-left bg-white text-black py-4 px-6 w-72 text-lg transition hover:bg-gray-200"
        >
          <img
            src={require("./GitHub-Mark-32px.png")}
            alt="Github Logo"
            className="inline mr-4"
            width={32}
            height={32}
          />
          Login with GitHub
        </a>
      </div>
    </div>
  );
}
