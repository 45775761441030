import React from "react";

export const ProtectedRoute = ({ component }) => {
  // const Component = withAuthenticationRequired(component, {
  //   onRedirecting: () => (
  //     <div className="flex-1 flex justify-center align-middle">
  //       <div className="h-20 w-20 relative self-center ">
  //         <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
  //       </div>
  //     </div>
  //   ),
  // });
  const Component = component;

  return <Component />;
};
