import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import fetch from "../helpers/fetch";

export default function Repositories(props) {
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    const doFetch = async () => {
      const { data } = await fetch("/api/repo", {});
      setRepos(data);
    };
    doFetch();
  }, []);

  return (
    <div className="flex flex-row flex-1">
      <nav className="bg-gray-900 py-2 px-4 max-w-md flex flex-col">
        <div className="font-bold mb-2 text-gray-300 uppercase text-sm">
          Your repositories
        </div>
        {repos.map((repo) => (
          <NavLink
            className={({ isActive }) =>
              `transition ${
                isActive ? "text-white" : "text-gray-400 hover:text-gray-300"
              } `
            }
            to={`/repos/${repo.owner}/${repo.name}`}
          >
            {repo.owner} / {repo.name}
          </NavLink>
        ))}
      </nav>
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
}
