export default async function fetchWrap(resource, config) {
  let response = await fetch(resource, config);
  const loginPage =
    window.location.protocol + "//" + window.location.host + "/login";

  if (
    !response.ok &&
    response.status === 401 &&
    window.location.href !== loginPage
  ) {
    window.location =
      window.location.protocol + "//" + window.location.host + "/login";
  }

  return await response.json();
}
