import React from "react";
import { Route, Routes, Navigate, Link } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import useToggle from "./hooks/useToggle";
import Repo from "./pages/Repo";
import Repositories from "./pages/Repositories";
import Setup from "./pages/Setup";
import Login from "./pages/Login";

function App() {
  const user = null;
  const logout = () => {};
  const isLoading = false;

  const [showUserDropdown, toggleUserDropdown] = useToggle();

  return (
    <div className="font-sans leading-normal tracking-normal bg-gray-800 text-gray-100 min-h-screen flex flex-col">
      {user ? (
        <nav className="py-4 px-6 flex flex-row content-center align-middle bg-gray-700 text-gray-100 w-full">
          {[
            { name: "Repositories", to: "/" },
            { name: "Set up", to: "/setup" },
          ].map(({ name, to }, index) => (
            <div
              className={`leading-relaxed flex align-middle transition hover:text-gray-200 ${
                index ? "ml-6" : null
              }`}
              key={to}
            >
              <Link to={to}>{name}</Link>
            </div>
          ))}
          <div
            className="relative ml-auto h-8 cursor-pointer"
            onClick={toggleUserDropdown}
          >
            <img
              src={user.picture}
              className="rounded overflow-hidden max-h-full"
              alt={user.name}
            />
            {showUserDropdown ? (
              <div className="w-52 rounded rounded-tr-none absolute bg-white text-gray-800 right-0">
                <button
                  className="py-1 px-4 bg-white transition hover:bg-gray-100 w-full"
                  onClick={() => logout()}
                >
                  Logga ut
                </button>
              </div>
            ) : null}
          </div>
        </nav>
      ) : null}
      <Routes>
        <Route
          path="repos"
          element={<ProtectedRoute component={Repositories} />}
        >
          <Route path=":org">
            <Route path=":repo" element={<Repo />}></Route>
          </Route>
        </Route>
        <Route path="setup" element={<ProtectedRoute component={Setup} />} />
        <Route
          path="/"
          element={<div>{isLoading ? null : <Navigate to="repos" />}</div>}
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
